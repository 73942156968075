import { config } from "../config";

enum METHOD {
  GET = "GET",
  POST = "POST",
  // PUT = 'PUT',
  // DELETE = 'DELETE',
}

/* eslint-disable @typescript-eslint/no-explicit-any */
type TRequest = <T>(methodName: string, data?: any) => Promise<T>;

interface IResponse<T> {
  Table: T;
}

const transport = async <T>(
  method: string,
  methodName: string,
  data: Record<string, unknown> = {},
  token?: string,
  signal?: AbortSignal
): Promise<T> => {
  let path: string;
  const prod = !config.production;
  if (prod) {
    path = `${config.api.market.domainProd}`;
  } else {
    path = `${config.api.market.domain}/${config.api.market.port}/Json`;

    // Добавление параметра для предпрода
    try {
      const test_methods_string =
        process.env.NEXT_PUBLIC_API_MARKET_API_REQUESTS_METHODS_TYPES_WITH_1;

      if (test_methods_string) {
        const test_methods_arr = JSON.parse(test_methods_string);

        if (test_methods_arr.includes(methodName)) {
          data.MethodType = 1;
        }
      }
    } catch (err) {
      console.error("Ошибка добавления параметра", err);
    }
  }

  const body = {
    CRC: "",
    Packet: {
      JWT: "null",
      MethodName: methodName,
      ServiceNumber: data.serviceNumber || prod
        ? config.api.market.serviceNumberProd
        : config.api.market.serviceNumber,
      Data: data,
    },
  };

  const timeName = methodName + "-" + Math.random() + " TIME";

  if (process.env.NODE_ENV === "development") {
    console.time(timeName);
    console.log("------------- LOG REQUEST -------------");
    console.log(`Path: ${path}`);
    console.log(`Method: ${methodName}`);
    console.log("Request:", JSON.stringify(body));
  }
  const start_time = new Date().getTime();

  const response = await fetch(path, {
    method: method,
    body: JSON.stringify(body),
    signal,
  });

  // const port = response.headers.get('x-server');

  const json: IResponse<T> = await response.json();

  if (process.env.NODE_ENV === "development") {
    console.log(`------------- LOG RESPONSE  -------------`);
    console.log(`Method: ${methodName}`);
    console.timeEnd(timeName);
    console.log(json);
  }

  const result = json.Table;

  const currentTime = new Date().getTime();

  if (currentTime - start_time > 500) {
    if (methodName === "EMark.GetListing") {
      console.log(`${methodName} 500+, ${JSON.stringify(body)}`);
    }
    console.log(
      `Ответ маркета с пингом 500+, метод: ${methodName}, время: ${
        new Date().getTime() - start_time
      }, сессия: ${data.SessionGuid || "Нет"}`
    );
  }

  return result;
};

export const request = {
  get: transport.bind(this, METHOD.GET) as TRequest,
  post: transport.bind(this, METHOD.POST) as TRequest,
};
