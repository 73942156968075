import classNames from "classnames";
import moment from "moment";
import React, { Component, FC, Fragment, ReactElement } from "react";
import { asyncModal } from "../../../helpers/asyncModal";
import { IList } from "../../Body";
import { Modal } from "../../modalComponents";

import styles from "./index.module.scss";
import { Button } from "ui-kit-euroopt";

interface IFieldModal {
  close: () => void;
  title: string;
  list: IList[];
  listEd: IList[];
  idx: number;
}

interface IState {
  activeIdx: number;
  date?: string;
  active?: boolean;
  activeTab: 'emall' | 'edostavka';
  openList: {
    [x: string]: boolean;
  };
}

interface ICard {
  name: string;
  info: string;
  type?: 'emall' | 'edostavka';
}

const Card: FC<ICard> = ({ name, info }) => {
  return (
    <div className={styles.card}>
      <span className={styles.card__name}>{name}</span>
      <span className={styles.card__info}>{info}</span>
    </div>
  );
};

class FieldModal extends Component<IFieldModal, IState> {
  constructor(props: IFieldModal) {
    super(props);
    this.state = {
      activeIdx: this.props.idx,
      openList: {},
      activeTab: 'emall'
    };
  }
  listHeight = 260;
  fieldRef = React.createRef<HTMLDivElement>();

  apply = (): void => {
    this.props.close();
  };

  scrollTo = (id: number) => {
    const element = document.getElementById(`filter_${id}`);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", inline: "center" });
    }
  };

  componentDidMount() {
    this.scrollTo(this.state.activeIdx);
  }

  renderWinner = (id: number, name: string, isPrizeType?: boolean) => {
    const { list } = this.props;
    const { activeIdx, openList } = this.state;

    const curList = list[activeIdx - 1].Winners;
    const filterProp = isPrizeType ? "PrizeType" : "PrizeId";
    const filterCurList = curList.filter((el) => el[filterProp] === id);
    const index = `${id}_${activeIdx}`;

    return (
      <>
        {filterCurList
          .filter((el, idx) =>
            filterCurList.length > 4
              ? idx > 4 && !openList[index]
                ? false
                : true
              : true
          )
          .map((el, idx) => (
            <>
              {idx === 0 && <span className={styles.titleText}>{name}</span>}
              <Card name={el.Winner} info={el.GamePrizeDescription} />
            </>
          ))}
        {!openList[index] && filterCurList.length > 5 ? (
          <Button
            mix={styles.button}
            onClick={() => {
              const copy = JSON.parse(JSON.stringify(openList));
              copy[index] = true;

              this.setState({ openList: copy });
            }}
          >
            Смотреть все
          </Button>
        ) : null}
      </>
    );
  };

  render(): ReactElement {
    const { close, list } = this.props;

    return (
      <Fragment>
        <Modal.Swiper onSwipeBottom={close} />
        <Modal.Close onClose={close} />
        <h3 className={styles.title}>Розыгрыши и победители</h3>

        <div className={styles.field} ref={this.fieldRef}>
          <div className={styles.tabs}>
            {list.map((el, idx) => (
              <div
                key={idx}
                id={`filter_${idx + 1}`}
                onClick={() =>
                  this.setState({ activeIdx: idx + 1 }, () => {
                    this.scrollTo(idx + 1);
                  })
                }
                className={classNames(
                  styles.tabs__tab,
                  this.state.activeIdx === idx + 1 && styles.tabs__tab__active
                )}
              >
                <span>
                  {moment(el.DrawDate).format("DD.MM")}
                </span>
              </div>
            ))}
          </div>
          <div className={styles.wrapper}>
            {list[this.state.activeIdx - 1].Winners.length ? (
              <>
                {this.renderWinner(710, "Квартира в Минске")}
                {this.renderWinner(709, "Тур на Шри-Ланку")}
                {this.renderWinner(708, "Денежный приз 1 000 BYN")}
                {this.renderWinner(707, "Денежный приз 500 BYN")}
                {this.renderWinner(694, "Денежный приз 50 000 BYN")}
                {this.renderWinner(706, "Автомобиль Belgee X50")}
                {this.renderWinner(1, "Сертификаты", true)}
                {this.renderWinner(700, "IPhone 15")}
                {this.renderWinner(699, "IPhone 15")}
                {this.renderWinner(698, "IPhone 15")}
                {this.renderWinner(697, "Пылесосы MAUNFELD")}
                {this.renderWinner(702, "Электросамокаты Artway")}
                {this.renderWinner(703, "Роботы-пылесосы Xiaomi")}
                {this.renderWinner(704, "Роботы-мойщики окон Даджет")}
                {this.renderWinner(705, "Умные колонки Яндекс")}
                {this.renderWinner(691, "Телевизоры ARTEL")}
              </>
            ) : (
              <span className={styles.wrapper__null}>
                Ожидается розыгрыш
                <br />
                <br />
                {this.state.activeIdx === list.length ? (
                    <>
                      Квартира в Минске
                    </>
                ) : (
                    <>
                      Денежный приз 500 р.
                      <br />
                      Денежный приз 1 000 р.
                    </>
                )}
              </span>
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

export const openFieldModal = (list: IList[], idx: number, listEd?: IList[]): Promise<void> =>
  asyncModal(
    FieldModal,
    { list, idx, listEd },
    {
      closeOnRouteChange: false,
      classNames: {
        modal: classNames("swiping", styles.modal_field),
      },
      showCloseIcon: false,
      focusTrapped: false,
    }
  );
