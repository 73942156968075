import { FC, ReactElement, ReactNode, useEffect, useState } from 'react';

interface IProps {
	children: ReactNode;
	defaultVisible?: boolean;
	width?: number;
}

export const Mobile: FC<IProps> = ({ children, defaultVisible = false, width = 992 }) => {
	const [visible, setVisible] = useState(defaultVisible);

	const calculate = (): void => {
		const current = window.innerWidth < width;
		setVisible(current);
	};

	useEffect(() => {
		calculate();
		window.addEventListener('resize', calculate);
		return () => {
			window.removeEventListener('resize', calculate);
		};
	}, []);

	return visible ? (children as ReactElement) : null;
};
