import { Icon } from "ui-kit-euroopt";
import cls from "../Footer/index.module.scss";
import { Adaptive } from "../adaptive";

const Footer = () => {
  return (
    <footer id="footer" className={cls.footer}>
      <div className={cls.footer__wrapper}>
        <Adaptive.Desktop>
          <img className={cls.footer__image} src="assets/images/flower.png" alt="Цветок"/>
        </Adaptive.Desktop>
        <div className={cls.footer__results}>
          <a href="/assets/files/winners-on-chill3.pdf" target="_blank">
            Результаты розыгрышей по рекламной игре «Е-везунчики. На чиле!» (12.06–06.08.2024) <Icon name="chevron-12" />
          </a>
        </div>
        <div className={cls.footer__results}>
          <a href="/assets/files/winners-on-drive2.pdf" target="_blank">
            Результаты розыгрышей по рекламной игре «Е-везунчики. На драйве!» (10.04–04.06.2024) <Icon name="chevron-12" />
          </a>
        </div>
        <div className={cls.footer__results}>
          <a href="/assets/files/winners-love.pdf" target="_blank">
              Результаты розыгрышей по рекламной игре «Е-везунчики. Это любовь!» (17.01–12.03.2024) <Icon name="chevron-12" />
          </a>
        </div>
        <div className={cls.footer__results}>
          <a href="/assets/files/winners-doubling.pdf" target="_blank">
            Результаты розыгрышей по рекламной игре «Е-везунчики. Удваиваемся!» (28.09 – 22.11.2023) <Icon name="chevron-12" />
          </a>
        </div>
        <div className={cls.footer__copyright} dangerouslySetInnerHTML={{ __html: `Рекламная игра «Е-везунчики. На домашнем» Срок проведения с 14.08.2024 по 10.12.2024 (включая период выдачи призов и публикации результатов). Свидетельство о регистрации №4454 от 31.07.2024 года, выданное Министерством антимонопольного регулирования и торговли Республики Беларусь. ООО «ЭфСиБи Бел», УНП 193185741` }} />
      </div>
    </footer>
  );
};
export default Footer;
