import { FC } from 'react';

interface IProps {
	mix?: string;
	children?: string | JSX.Element | JSX.Element[];
}

export const ContainerFluid: FC<IProps> = ({ children, mix }) => {
	return (
		<div className={mix}>
			{children}
		</div>
	);
};
